.container {
  position: relative;
}

.iconButton {
  cursor: pointer;
}

.dropdown {
  position: absolute;
  right: 0;
  margin-top: 8px;
  width: 166px;
  min-height: 60px;
  background: #1c1a24;
  color: var(--white);
  padding: 10px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  z-index: 9999;
  font: var(--font-14-semibold);
  justify-content: center;
  top: 40px;
  cursor: pointer;

  &:hover {
    background: var(--btn-hover-on-bg);
  }
}

.menuItem {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.iconButton:hover {
  svg rect {
    fill: var(--btn-hover-on-bg);
  }
}
