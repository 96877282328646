.withdraw {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;

  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .coinSelect {
      width: 50%;
    }

    .networkSelect {
      width: 50%;
      display: flex;
      min-width: 290px;
      position: relative;
      margin-left: 20px;
    }
  }

  .promptWrap {
    width: 100%;
    position: relative;
    display: flex;
    gap: 20px;
  }

  .description {
    text-align: center;
    color: var(--white);
    padding-top: 8px;
    font-size: 14px;
    line-height: 20px;
  }
}

.mobile.withdraw {
  gap: 0px;

  .top {
    flex-direction: column;
    gap: 45px;
    min-width: auto;
    margin: 0 0 45px;

    .coinSelect {
      width: 100%;
    }

    .networkSelect {
      width: 100%;
      min-width: auto;
      margin: 0;
    }
  }

  .promptWrap {
    flex-direction: column;
  }
}

.loader {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.withdraw {
  display: flex;
  flex-direction: column;
  width: 100%;

  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 11px;

    .select {
      display: flex;
      min-width: 240px;
      position: relative;
    }
  }

  .button {
    // margin-top: 20px;
  }
}

.assetPrompt {
  padding-top: 20px;
}

.assetPrompt>div {
  width: 100%;
}

.input {
  flex-direction: row;
  align-items: center;
  flex: 0 0 60%;
  margin-bottom: 20px !important;
}

.coins {
  position: relative;
  min-width: 240px;
  display: flex;
  flex-direction: column;
  z-index: 14;
  // margin: 15px 0 0 0;
  // padding-bottom: 32px;
}

.available {
  color: var(--foreground-3);
}

.inputAside {
  display: flex;
  align-items: center;
  gap: 10px;
}

.maxBtn {
  height: 30px;
  padding: 0 10px;
  font: var(--font-12-semibold);
}

.coinSelect {
  width: auto;
}

.buttonWrap {
  margin: 0 30px;
}

.promptWrap {
  width: 100%;
  position: relative;
  display: flex;
  gap: 20px;
}

.receive {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font: var(--font-17-semibold);
  color: white;
  padding-top: 20px;
  margin-top: 12px;
  border-top: 0.5px solid rgba(255, 255, 255, 0.1);
}

.fee {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font: var(--font-17-semibold);
}

.mobile {
  .coins {
    flex-direction: column;
    gap: 35px;
    min-width: auto;
    margin: 0 0 45px;

    .input {
      width: 100%;
      flex: none;
    }
  }

  .willReceiveWrap {
    margin: 20px 0 0;

    .receive {
      font: var(--font-20-bold);
      gap: 20px;
    }

    .receiveDescription {
      white-space: nowrap;
    }

    .receiveValue {
      flex-grow: 1;
      overflow: hidden;

      white-space: nowrap;
      text-align: end;
      text-overflow: ellipsis;
    }

    .fee {
      line-height: 20px;
    }
  }

  .promptWrap {
    flex-direction: column;
  }

  .whiteList {
    margin-bottom: 38px;
  }

  &.withdraw {
    .button {
      white-space: initial;
      height: auto;
      padding: 12.5px 20px;
      line-height: 20px;
    }
  }
}

.symbolEllipsis {
  max-width: 420px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.willReceiveWrap {
  background-color: var(--background-2);
  border: 0.5px solid var(--foreground-1);
  color: white;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.withdrawBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
}

.link {
  position: relative;
  text-decoration: none;
  display: inline-block;
  line-height: 1; // можно регулировать высоту строки

  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: 0.5px;
    background-color: currentColor;
    bottom: -0.5px; // ← отступ от текста до линии
  }

  &:hover::after {
    background-color: var(--foreground-2); // кастомный цвет при наведении
  }

  &:hover {
    color: var(--foreground-2);
  }
}