@import "./src/styles/mixins";

.wrapper {
    height: 100%;
    padding: 40px 0;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.headerWrapp {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 64px;
}

.header {
    display: flex;
    flex-direction: column;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.img {
    max-width: 465px;
}