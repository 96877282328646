.root {
  font: var(--font-12-semibold);
}

.tpTriggerNegative {
  color: #077227;
}

.slTriggerNegative {
  color: var(--second-red-text);
}
