.container {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.btnContainer {
  display: flex;
  gap: 15px;
  flex-direction: column;
}

.btns {
  display: flex;
  gap: 15px;
  width: 100%;

  button {
    width: 50%;
  }
}

.link {
  font: var(--font-12-semibold);
  color: var(--foreground-2);
  border-bottom: 1px solid var(--foreground-2);
  margin-left: 3px;
}

.support {
  font: var(--font-12-semibold);
  color: var(--foreground-2);
  display: flex;
  justify-content: center;
}
