.mainTabButtons {
  display: flex;
  gap: 10px;
}

.mainTabButtons1 {
  display: flex;
  gap: 10px;
}

.title {
  color: white;
  font-size: 16px;
  padding-bottom: 12px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  padding: 10px;
  border: 1px solid white;
  border-radius: 10px;
}