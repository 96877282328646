@import 'src/styles/mixins';

.transfer {
  display: flex;
  flex-direction: column;
  width: 100%;

  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;

    .select {
      display: flex;
      min-width: 240px;
      position: relative;
    }

    .button {
      --transfer-color: var(--foreground-3);
      margin-top: 20px;
      padding: 5px;

      svg {
        width: 17px;
        height: 17px;
      }
    }
  }

  .coinSelect {
    width: auto;
  }
}

.input {
  width: 100%;
  max-width: 800px;
}

.available {
  color: var(--foreground-3);
}

.inputAside {
  display: flex;
  align-items: center;
  gap: 10px;
}

.maxBtn {
  height: 30px;
  padding: 0 10px;
  font: var(--font-12-semibold);
}

.buttonWrap {
  margin: 0 30px;
}

.buttonSubmit {
  margin-top: 20px;
}

.informationBanner {
  background: var(--background-2);
  border-radius: 25px;
  padding: 20px 25px 20px 20px;
  margin-bottom: 20px;
  color: var(--foreground-3);
  font-weight: 600;
}

.infromationWarningMessage {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font: var(--font-14-semibold);
}

.warningWalletText {
  font: var(--font-14-extrabold);
  @include gradientText(var(--gradient-foreground));
}

.warningCoinsText {
  font: var(--font-14-extrabold);
  @include gradientText(var(--btn-hover-grad));
}

.mobile {
  &.transfer {
    .top {
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }

    .buttonWrap {
      --transfer-color: var(--foreground-3);
      margin: 15px 0 30px;
      width: 40px;
      height: 40px;
    }

    .informationBanner {
      margin: 0;
    }

    .infromationWarningMessage {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 20px;

      font: var(--font-14-semibold);
      color: var(--foreground-3);
      flex-grow: 1;
    }
  }

  .explanation {
    margin-bottom: 5px;
  }

  .actionButton {
    margin-top: 20px;
    height: auto;
  }
}

.helpText {
  display: flex;
  gap: 8px;
  justify-content: space-between;
  font: var(--font-17-semibold);
}

.assetWrap {
  background-color: var(--background-2);
  border: 0.5px solid var(--foreground-1);
  color: white;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.actionButton {
  margin-top: 24px;
}

.results {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  max-width: 340px;
  color: white;
}

.mainInfo {
  font: var(--font-40-extrabold);
}

.explanation {
  font: var(--font-14-semibold);
  color: var(--foreground-2);
  margin-top: 5px;
}

.tableItem {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;

  span {
    font: var(--font-14-semibold);
    color: var(--foreground-2);
  }

  span:last-child {
    font: var(--font-14-extrabold);
    color: var(--foreground-3);
    display: flex;
    align-items: center;

    .button {
      width: 30px;
      height: 30px;
      padding: 0;
      margin-left: 10px;

      svg {
        width: 20px;
        height: 16px;

        path {
          stroke: none;
        }
      }
    }
  }

  .copyButton {
    margin-left: 10px;
  }
}

.statusAndDate {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0 30px;
  font: var(--font-12-semibold);
  padding: 8px 10px;
  border-radius: 50px;
  line-height: 16px;
  background: var(--background-3);
  color: white;
  gap: 5px;

  &.loading .statusName,
  &.loading .statusDate {
    color: var(--foreground-2);
  }

  &.failed .statusName {
    color: var(--second-red-text);
  }

  svg {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .statusDate {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-left: 5px;

    &::before {
      content: '';
      display: block;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: var(--foreground-2);
    }
  }
}