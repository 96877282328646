.desctiption {
  color: var(--foreground-2);
  font: var(--font-14-semibold);
  text-align: center;
  margin-bottom: 30px;
}

.btns {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  margin-bottom: 20px;
}

.link {
  border-bottom: 1px solid var(--foreground-2);
  font: var(--font-12-semibold);
  color: var(--foreground-2);
  margin-left: 2px;
}

.support {
  font: var(--font-12-semibold);
  color: var(--foreground-2);
  display: flex;
  justify-content: center;
}
