.container {
  position: relative;
  box-sizing: border-box;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  margin: 0 0 25px;
  border-bottom: 1px solid var(--foreground-1);
  transition: border 0.2s ease;

  &:has(.input:not(:read-only):hover),
  &:has(.input:not(:read-only):focus) {
    border-color: var(--foreground-3);
  }

  &:has(.input.invalid),
  &:has(.input.invalid:focus) {
    border-color: var(--second-red-text);
  }

  .label {
    margin-bottom: 5px;
    color: var(--foreground-3);

    &>*:first-child {
      font: var(--font-14-bold);
    }
  }

  &.infoWrapVisible {
    margin-bottom: 50px;
  }

  &.extraSmall {
    margin: 0 0 20px;
  }
}

.extraSmall {
  height: 40px;
}

.small {
  height: 65px;
}

.medium {
  height: 70px;
}

.large {
  height: 80px;
}

.inputWrapper {
  flex: 1;
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
}

.input {
  max-width: 100%;
  width: 100%;
  color: var(--foreground-3);
  border: none;
  background: transparent;
  outline: 0;

  &::placeholder {
    color: var(--foreground-1);
    font: inherit;
  }

  &:disabled {
    opacity: 0.5;
  }

  &:hover+.border,
  &:focus+.border {
    background-color: var(--foreground-3);
  }

  .extraSmall & {
    font: var(--font-20-bold);
  }

  .small &,
  .medium & {
    font: var(--font-17-bold);

    &[type='password'] {
      font-size: 25px;
      letter-spacing: 3px;
    }
  }

  .large & {
    font: var(--font-25-extrabold);
  }
}

.infoWrap {
  position: absolute;
  left: 0;
  right: 0;
  top: calc(100% + 5px);
  display: flex;
  justify-content: space-between;
  gap: 10px;
  font: var(--font-14-semibold);

  .extraSmall & {
    font: var(--font-12-semibold);
  }
}

.errorMessage {
  color: var(--second-red-text);
}

.info {
  margin-left: auto;
  color: var(--foreground-2);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.mobile.container {
  max-width: none;
  margin: 0 0 5px;

  .label {
    &>*:first-child {
      color: var(--foreground-3);
      line-height: 20px;
    }
  }

  &.infoWrapVisible {
    margin-bottom: 25px;
  }

  .infoWrap {
    font: var(--font-12-semibold);
    line-height: 17px;
  }

  .input {

    .small &,
    .medium & {
      font: var(--font-16-bold);
      line-height: 35px;
    }
  }
}

.clearButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  width: 40px;
  background: var(--foreground-1);
}