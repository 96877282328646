.modal {
  width: 500px;
  padding: 30px;
  text-align: left;
}

.modalMedium {
  width: 400px;
}

.closeBtn {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background: #1c1a24;
  color: white;
  cursor: pointer;
}

.headerTitle {
  font: var(--font-20-semibold);
}

.titleBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

.titleSetPin {
  justify-content: center;
}

.titleReset {
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.iconSuccess {
  width: 60px;
  height: 60px;
}

.iconHeader {
  justify-content: center;
}
