.order {
  display: grid;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
  grid-auto-flow: column;
  background: var(--background-2);
  transition: background-color, opacity 0.2s linear;

  &.tab {
    grid-template-columns: 2.4fr 1fr 1fr 1fr 30px;
    padding: 20px;
    border-radius: 15px;
  }

  &.tabTigerX {
    grid-template-columns: 2.4fr 1fr 1fr 1fr 1fr 30px;
    & > *:nth-child(5) {
      text-align: right;
    }
    & > *:nth-child(6) {
      text-align: right;
    }
  }

  &.table {
    grid-template-columns: 2.4fr 1fr 1fr 1fr 30px;
    padding: 10px;
    border-radius: 12px;

    & > *:nth-child(4) {
      text-align: right;
    }
  }

  &.deleted {
    opacity: 0.2;
    pointer-events: none;
  }

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  &:hover {
    background: var(--btn-hover);
  }
}

.tableTigerX {
  grid-template-columns: 2.4fr 1fr 75px 1fr 1fr 30px !important;

  & > *:nth-child(5) {
    text-align: right;
  }
}

.item {
  color: var(--foreground-3);

  .tab & {
    font: var(--font-14-bold);

    &:nth-child(4) {
      text-align: right;
    }
  }

  .table & {
    font: var(--font-12-semibold);

    &:nth-child(5) {
      text-align: right;
    }
  }
}

.description {
  margin-top: 3px;
  font: var(--font-12-semibold);
  color: var(--foreground-2);
}

.currency {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;

  .table & {
    font: var(--font-12-extrabold);
  }

  & > *:not(:last-child):not(:nth-last-child(2)):after {
    content: '\2022';
    display: inline-block;
    margin-left: 4px;
    color: var(--foreground-2);
  }
}

.quote {
  color: var(--foreground-2);
}

.new {
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  background-image: var(--gradient-foreground);
  font: var(--font-14-bold);
}

.closeButton {
  height: 30px;
  width: 30px;
  padding: 0;
}

.wrapper {
  display: flex;
  justify-content: end;
}

.noWrapContainer {
  display: flex;
}

.orderSide {
  margin: -4px 0 -4px 5px;
}

.orderSideTab {
  margin: -1px 0 0 3px;
}

.itemWrapper {
  display: flex;
  align-items: center;
  gap: 6px;
  color: var(--foreground-3);
  font: var(--font-12-semibold);
}

.orderTabWrapper {
  display: flex;
  flex-direction: column;
  color: var(--foreground-3);
  font: var(--font-14-bold);
}

.tpSl {
  font: var(--font-12-semibold);
}

.tpSlBold {
  font: var(--font-14-bold);
}
