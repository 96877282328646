.headers {
  display: flex;
  gap: 41px;
  justify-content: space-between;
}

.price {
  display: inline-block;
  color: var(--foreground-3);
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 32px;
  line-height: 50px;
}

.price.balance {
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  background-image: var(--gradient-foreground);
}

.symbol {
  color: var(--general-gray);
}

.balanceInfo {
  display: flex;
  justify-content: space-between;
}

.itemBlock {
  display: flex;
  flex-direction: column;
  gap: 11px;
}

.head {
  font: var(--font-12-medium);
  color: var(--white);
  height: 24px;
  display: flex;
  align-items: center;
}

.priceInfo {
  color: var(--foreground-3);
  font-family: var(--font-family);
  font-weight: 700;
  font-size: 32px;
  line-height: 50px;
}

.hintBlock {
  position: relative;
  display: flex;
  align-items: center;
  gap: 6px;
}

.unimmr {
  color: #077227;
}

.hintMargin {
  margin-left: 5px;
}
