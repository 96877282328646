.container {
  display: flex;
  gap: 10px;
}

.text {
  color: var(--foreground-2);
  font: var(--font-14-semibold);
}

.arrow {
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4.5px;
  background: #1c1a24;
  color: white;
  position: relative;
  top: 4px;

  & svg {
    width: 18px;
    height: 18px;
    position: relative;
    left: 25%;
    top: 31%;
  }
}
