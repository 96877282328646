.container {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.headContnet {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.title {
  font: var(--font-17-semibold);
}

.checkboxItem {
  font: var(--font-12-semibold);
  color: var(--foreground-2);
  display: flex;
  align-items: center;
}

.btnContainer {
  display: flex;
  gap: 15px;
  flex-direction: column;
}

.btns {
  display: flex;
  gap: 15px;
  width: 100%;

  button {
    width: 50%;
  }
}
