.container {
  display: flex;
  gap: 10px;
  background: #f970520a;
  padding: 10px;
  align-items: center;
}

.text {
  color: var(--second-red-text);
  font: var(--font-12-semibold);
}
