.container {
  display: flex;
  flex-direction: column;
  gap: 30px;
  font: var(--font-14-semibold);
}

.btns {
  display: flex;
  gap: 15px;
  width: 100%;

  button {
    width: 50%;
  }
}
