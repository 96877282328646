.root {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  gap: 3px;
}

.top {
  display: flex;
  align-items: baseline;
  gap: 5px;
  width: 100%;
}

.symbol {
  white-space: nowrap;

  &.disabled {
    opacity: 0.3;
  }
}

.name {
  flex-grow: 1;

  font: var(--font-14-medium);
  color: var(--foreground-2);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  .large & {
    font: var(--font-17-semibold);
  }

  &.disabled {
    color: var(--red-text);
  }
}

.fee {
  width: 100%;

  font: var(--font-12-semibold);
  color: var(--foreground-2);

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &.disabled {
    color: var(--red-text);
  }
}

.dangerText {
  color: var(--red-text);
  font: var(--font-12-semibold);
  overflow: hidden;
  text-overflow: ellipsis;
}