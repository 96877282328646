.component {
  width: 370px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.title {
  word-break: break-all;
  margin: 0;
}

.msg {
  margin-top: 5px;
  color: var(--foreground-3);
  word-break: break-all;
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.buttonsWrapper {
  display: flex;
  gap: 15px;
}

.button {
  width: 100%;
  margin-top: 10px;
}

.label {
  display: block;
  margin-bottom: 20px;
}

.titleBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

.closeBtn {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background: #1c1a24;
  color: white;
  cursor: pointer;
}

.headerTitle {
  font: var(--font-20-semibold);
}
