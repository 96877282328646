.wrapper {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 64px;
}

.transactionWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 800px;
  padding: 32px;
  background-color: var(--background-1);
  border-radius: 20px;
  // border: 0.5px solid var(--foreground-2);
}

.header {
  padding-bottom: 60px;
}

.content {
  max-width: 600px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 0 16px;
  height: 100%;
  width: 100%;
  padding-bottom: 32px;
}

.errorContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 460px;
  min-height: 310px;
  line-height: 22px;
  height: 80%;
  color: white;
}

.errorWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 26px;
  padding-left: 12px;
}

.errorTitle {
  padding-left: 4px;
}

.error {
  padding-bottom: 16px;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 310px;
  padding-bottom: 40px;
}