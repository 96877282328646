.prompt {
  padding: 20px 25px 20px 20px;
  gap: 20px;
  border-radius: 25px;
  background: var(--background-2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .description {
    font: var(--font-14-semibold);
    color: var(--foreground-3);
    flex-grow: 1;
  }

  .mark {
    border-radius: 15px;
    width: 40px;
    height: 40px;
    background: var(--btn-hover-grad);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
  }

  .coin {
    color: var(--second-purple-text);
  }

  .networkSymbol {
    color: var(--second-red-text);
  }

  .networkType {
    color: var(--foreground-2);
  }
}

.mobile {
  .description {
    font: var(--font-12-semibold);
    line-height: 17px;
  }
}
