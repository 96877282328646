.description {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font: var(--font-14-semibold);
  margin-bottom: 20px;
}

.alert {
  color: var(--second-red-text);
}

.link {
  color: var(--foreground-2);
  border-bottom: 1px solid var(--foreground-2);
}

.info {
  display: flex;
  flex-direction: column;
  gap: 10px;
  div {
    color: var(--white);
  }
  margin-bottom: 20px;
}

.alert {
  margin-bottom: 30px;
}

.btnContainer {
  display: flex;
  gap: 15px;
  flex-direction: column;
}

.btns {
  display: flex;
  gap: 15px;
  width: 100%;

  button {
    width: 50%;
  }
}

.checkboxItem {
  font: var(--font-12-semibold);
  color: var(--foreground-2);
  display: flex;
  align-items: center;
  justify-content: center;
}
