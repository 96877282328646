.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
}

.inputContainer {
  display: flex;
  gap: 5px;
}

.inputBox {
  width: 40px;
  height: 55px;
  text-align: center;
  font: var(--font-18-semibold);
  border-radius: 10px;
  background-color: var(--background-3);
  outline: none;
  transition: border-color 0.2s ease-in-out;
  color: var(--white);
  border: 1px solid var(--background-1);

  &.extraSpacing {
    margin-left: 12px;
  }
}

.inputBox:focus {
  border-color: var(--foreground-1);
}

.errorText {
  color: var(--second-red-text);
  font: var(--font-14-semibold);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
}

.errorLengthText {
  svg {
    height: 14px;
    width: 28px;
  }
}

.infoText {
  color: var(--foreground-2);
  text-align: center;
  font: var(--font-14-semibold);
}

.limitPincode {
  font: var(--font-12-semibold);
  color: var(--foreground-2);
  margin-bottom: 14px;
  text-align: center;
}

.counter {
  color: #1ACC81;
}

.disabled {
  .inputBox {
    color: #777;
    cursor: not-allowed;
    pointer-events: none;
  }
}