.deposit {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .coinSelect {
      width: 50%;
    }

    .networkSelect {
      width: 50%;
      display: flex;
      min-width: 290px;
      position: relative;
      margin-left: 20px;
    }
  }

  .promptWrap {
    width: 100%;
    position: relative;
    display: flex;
    gap: 20px;
  }

  .description {
    text-align: center;
    color: var(--white);
    padding-top: 8px;
    font-size: 14px;
    line-height: 20px;
  }
}

.mobile.deposit {
  gap: 0px;

  .top {
    flex-direction: column;
    gap: 45px;
    min-width: auto;
    margin: 0 0 45px;

    .coinSelect {
      width: 100%;
    }

    .networkSelect {
      width: 100%;
      min-width: auto;
      margin: 0;
    }
  }

  .promptWrap {
    flex-direction: column;
  }
}

.loader {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}