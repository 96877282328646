.container {
  text-align: center;
}

.title {
  font: var(--font-25-bold);
  margin-bottom: 5px;
}

.desctiption {
  color: var(--foreground-2);
  font: var(--font-14-semibold);
  margin-bottom: 30px;
}

.btn {
  width: 100%;
}
