.withdraw {
  display: flex;
  flex-direction: column;

  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 11px;

    .select {
      display: flex;
      min-width: 240px;
      position: relative;
    }
  }

  .button {
    margin-top: 20px;
  }
}

.assetPrompt {
  padding-top: 20px;
}

.assetPrompt > div {
  width: 100%;
}

.input {
  flex-direction: row;
  align-items: center;
  width: 60%;
  flex: 0 0 60%;
}

.coins {
  position: relative;
  min-width: 240px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  z-index: 14;
  margin: 15px 0;
}

.available {
  color: var(--foreground-3);
}

.inputAside {
  display: flex;
  align-items: center;
  gap: 10px;
}

.maxBtn {
  height: 30px;
  padding: 0 10px;
  font: var(--font-12-semibold);
}

.coinSelect {
  width: auto;
}

.buttonWrap {
  margin: 0 30px;
}

.promptWrap {
  width: 100%;
  position: relative;
  display: flex;
  gap: 20px;
}

.willReceiveWrap {
  margin: 25px 0;
  color: var(--foreground-3);
}

.receive {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font: var(--font-25-extrabold);
}

.fee {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
  font: var(--font-14-semibold);
}

.mobile {
  .coins {
    flex-direction: column;
    gap: 35px;
    min-width: auto;
    margin: 0 0 45px;

    .input {
      width: 100%;
      flex: none;
    }
  }

  .willReceiveWrap {
    margin: 20px 0 0;

    .receive {
      font: var(--font-20-bold);
      gap: 20px;
    }

    .receiveDescription {
      white-space: nowrap;
    }

    .receiveValue {
      flex-grow: 1;
      overflow: hidden;

      white-space: nowrap;
      text-align: end;
      text-overflow: ellipsis;
    }

    .fee {
      line-height: 20px;
    }
  }

  .promptWrap {
    flex-direction: column;
  }

  .whiteList {
    margin-bottom: 38px;
  }

  &.withdraw {
    .button {
      white-space: initial;
      height: auto;
      padding: 12.5px 20px;
      line-height: 20px;
    }
  }
}
.symbolEllipsis {
  max-width: 420px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}


.pin {
  color: white;
  border: 1px solid white;
}

.limitPincode {
  font: var(--font-12-semibold);
  color: var(--foreground-2);
  margin-bottom: 20px;
  text-align: center;
}

.checkboxItem {
  font: var(--font-12-semibold);
  color: var(--foreground-2);
  display: flex;
  align-items: center;
  justify-content: center;
}
